interface TpsRecordInfo {
  tpsRecordId: string;
  tpsRecordSystemId: string; // SysID from SNOW
  tpsRecordType: TPSRecordType;
  state: State;
}

export interface AssessmentPayload {
  assessments: Assessment[];
}

export interface Assessment extends TpsRecordInfo {
  activities?: (Activity | Email)[];
  assessmentOutcome: string; // TODO change to assessmentOutcome
  closureReasons?: string;
  endDate?: number; // Marking this as optional as it's sometimes missing from the backend
  inheritedDdq?: boolean;
  inheritedDdqTpsRecordId?: string; // Parent record doing the inheriting
  isFavorited?: boolean;
  issues?: Issue[];
  tasks?: Task[];
  issueCount?: number;
  taskCount?: number;
  peerReview?: string;
  questionnaires?: Questionnaire[];
  securityTeam?: string;
  startDate?: number; // Marking this as optional as it's sometimes missing from the backend
  thirdParty?: ThirdPartyDetails[];
  thirdPartyContact?: string;
  tier?: number;
  vendor: string;
  name: string;
  redirectUrlParams?: string; //Intake TPS record will have this field
  assignedUsers?: User[]; // This does not come for 3P users.
}

export interface Questionnaire {
  id: string;
  tpsRecordSystemId: string;
  type: string;
  typeId: string;
  expiresOn?: number; // Date value represented in Epoch
  progressPercentage: number;
  state: State;
  assignedUser: User;
  dateCreated: Date; // Date value represented in UTC seconds
}

export enum SwimlaneType {
  INTAKE = 'intake',
  DDQ = 'ddq',
  ISSUE = 'issue',
}

export interface ThirdPartyDetails {
  name: string;
  primaryContactName: string;
  primaryContactEmail: string;
}

export interface User {
  type: UserType;
  userId: string;
  name?: string;
  emailAddress: string;
}

export interface Email {
  recipients: string;
  copied: string;
  subject: string;
  created: number; // Date value represented in Epoch
  tpsRecordId?: string; //Used to differentiate activity record type
  tpsRecordSystemId?: string; // SysID from SNOW
  tpsRecordType?: string; //Used to differentiate record type for redirection
}

export interface TpsRecordActivity {
  tpsRecordType?: string;
  tpsRecordId: string;
  tpsRecordSystemId: string;
  activities: Activity[];
  emailsSent: Email[];
  typeId?: string;
}

export interface ActivityPayload {
  activities: TpsRecordActivity[];
}

export interface Activity {
  type: ActivityType;
  previousValue: string;
  currentValue: string;
  personType: PersonType;
  personName: string;
  timestamp: number; // Date value represented in UTC seconds
  text: string;
  tpsRecordId?: string; //Used to differentiate activity record type
  tpsRecordSystemId?: string; // SysID from SNOW
  tpsRecordType?: string; //Used to differentiate record type for redirection
}

export interface Issue extends TpsRecordInfo {
  assignedUsers: User[];
  title: string;
  description: string;
  severity?: string; // Temporarily a string (08/03/23)
  relatedLinks: RelatedLink[];
  remediationSchedule?: RemediationSchedule;
  tier: string;
  assessmentOutcome: string; // Temporarily a string (08/07/23)
  category: string;
  issueOwnership?: string; // Used to differentiate issue ownership. Possible values "Third Party" "Amazon Internal"
}

export interface Task extends TpsRecordInfo {
  assignedUsers: User[];
  title: string;
  description: string;
  severity: string;
  dueDate: number;
}

export interface RemediationSchedule {
  start?: number; // Date value represented in UTC seconds
  end?: number; // Date value represented in UTC seconds
  dueDate?: number; // Date value represented in UTC seconds
}

export interface RelatedLink {
  siteName: string;
  url: string;
}

export interface ConsultPayload {
  consults: Consult[];
}

export interface Consult extends TpsRecordInfo {
  description: string;
  dateCreated: Date; // Date value represented in UTC seconds
  dateUpdated: Date;
  category: ConsultCategory;
  name: string;
  assignedUsers: User[];
}

export enum ConsultCategory {
  SECURITY = 'security',
  GENERAL = 'general',
}

export enum UserType {
  //1P user persona
  PRIMARY_OWNER = 'primary_owner',
  SECONDARY_OWNER = 'secondary_owner',
  TECHNICAL_OWNER = 'technical_owner',
  REQUESTER = 'requester',
  LEVEL_8_OWNER = 'level_8_owner',
  WATCH_LIST = 'watch_list',
  ASSIGNEE_TO = 'assignee_to',
  ASSESSOR = 'assessor',

  //3P User persona
  VENDOR = 'vendor',
  THIRD_PARTY_CONTACT = 'third_party_contact',

  // Other
  USER = 'user',
}

export interface UserDetails {
  name: string;
  userType: 'vendor' | 'sys_user';
  vendorName?: string;
  primaryContact?: boolean;
  tpsRecordSystemId: string;
  email: string;
  hasDualRole?: boolean;
  dualRoleTarget?: string;
  canImpersonate?: boolean;
  hasImpersonated?: boolean;
  impersonatedUser?: string;
}

export interface FavoritesPayload {
  favorite: Favorites;
}

interface Favorites {
  userId: string;
  createdAt: number;
  lastUpdatedAt: number;
  tpsRecord: { [key: string]: string };
}

export enum State {
  ABANDONED = 'abandoned',
  AWAITING_ASSESSOR = 'awaiting_assessor',
  AWAITING_REQUESTER = 'awaiting_requester',
  AWAITING_THIRD_PARTY = 'awaiting_third_party',
  CANCELLED = 'cancelled',
  CLOSED = 'closed',
  COMPLETE = 'complete',
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  NEW = 'new',
  OVERDUE = 'overdue',
  PENDING_FOR_THIRD_PARTY_REMEDIATION = 'pending_for_third_party_remediation',
  PENDING_TPS_REVIEW = 'pending_tps_review',
  QUEUED_FOR_TPRM = 'queued_for_tprm',
  READY_TO_TAKE = 'ready_to_take',
  SENT_TO_THIRD_PARTY = 'sent_to_third_party',
  TPS_REVIEW_LINKED = 'tps_review_linked',
  TPS_REVIEW_NOT_REQUIRED = 'tps_review_not_required',
  WAITING_ON_AMAZON_BUSINESS_OWNER = 'waiting_on_amazon_business_owner',
  SUBMITTED = 'submitted',
  RECEIVED = 'received',
  NOT_APPLICABLE = 'not_applicable',
  PAUSED = 'paused',
  NONE = 'none',
}

export enum AssessmentOutcomeStatus {
  BAR_MET = 'amazon_3p_security_bar_met',
  BAR_MET_EXCEPTIONS = 'amazon_3p_security_bar_met_with_exceptions',
  BAR_BYPASSED_EXCEPTIONS = 'amazon_3p_security_bar_bypassed_exception_approved',
  BAR_NOT_MET = 'amazon_3p_security_bar_not_met',
  IN_PROGRESS = 'in_progress',
  PENDING_REVIEW = 'pending_review',
  NOT_APPLICABLE = 'not_applicable',
  NONE = 'none',
}

export enum Tier {
  ONE = 'tier_1',
  TWO = 'tier_2',
  THREE = 'tier_3',
  FOUR = 'tier_4',
  NONE = 'none',
}

export enum PersonType {
  SYSTEM = 'system',
  USER = 'user',
}

// Severity
export enum Priority {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NONE = 'none',
}

export enum QuestionnaireType {
  INTAKE = 'intake',
  DDQ = 'ddq',
  SECURITY_FOLLOWUP = 'security_followup',
  GENERAL = 'general',
  MISC = 'misc',
  NONE = 'none',
}

export enum QuestionnaireRecordType {
  DDQ = 'ddq',
  SAMPLE_QUESTIONNAIRE = 'sample_questionnaire',
  ESG_QUESTIONNAIRE = 'esg_questionnaire',
  PRIVACY_QUESTIONNAIRE = 'privacy_questionnaire',
  BASIC_QUESTIONNAIRE = 'basic_questionnaire',
  REGISTRATION_QUESTIONNAIRE = 'registration_questionnaire',
  GIFT_QUESTIONNAIRE = 'gift_card_questionnaire',
  SECURITY_QUESTIONNAIRE = 'security_questionnaire',
  DISPLAY_SAME = 'same',
  DISPLAY_NONE = 'none',
}

export enum TPSRecordType {
  ASSESSMENT = 'assessment',
  ISSUE = 'issue',
  TASK = 'task',
  CONSULT = 'consult',
  SURVEY = 'survey',
  DDQ = 'ddq',
  SECURITY_FOLLOWUP = 'security_followup',
  ACTIVITY = 'activity',
  NONE = 'none',
}

//Different SNOW Records Starts with and commented how they are called in general
export enum TPSRecordIdPrefix {
  TPTA = 'tpta', //Assessment or TPTA Record
  TPRI = 'tpri', //Issue or TPRI Record
  TPRT = 'tprt', //Consult, Task Records starts with same record Id or TPRT Record
  AINST = 'ainst', //Questionnarie or DDQ, Security Follow-up Questionnarie or AINST Record
}

export interface SurveysPayload {
  surveys: Survey[];
}

export interface Survey extends TpsRecordInfo {
  category: SurveyCategory;
  progressPercentage: number;
  dueDate: number;
  assignedTo: string;
  name: string;
  assignedUsers: User[];
}

export enum SurveyCategory {
  SURVEY_ASSESSMENT = 'assessment',
  SURVEY_GENERAL = 'general',
  NONE = 'none',
}

//Below enum addresses activities in an order as per this log https://tiny.amazon.com/1awqg6obb/quipCmTRCXAc
//Some we are receiving from the backend but some we are adding for our panther files - TodoList, ThirdPartyTieringAssessmentCard Components
export enum ActivityType {
  STATE_CHANGE = 'state_change',

  //adding below for FE panther files and redirection requirements
  ASSESSMENT_STATE_CHANGE = 'assessment_state_change',
  ISSUE_STATE_CHANGE = 'issue_state_change',
  CONSULT_STATE_CHANGE = 'consult_state_change',
  TASK_STATE_CHANGE = 'task_state_change',
  QUESTIONNAIRE_STATE_CHANGE = 'questionnaire_state_change', //we had utility to differentiate Security Follow-up && DDQ

  INTAKE_COMPLETED = 'intake_completed',

  COMMENT = 'comment',
  ADDITIONAL_COMMENTS = 'additional_comments',

  //adding below for FE panther files and redirection requirements
  ASSESSMENT_COMMENT = 'assessment_comment',
  ISSUE_COMMENT = 'issue_comment',
  TASK_COMMENT = 'task_comment',
  QUESTIONNAIRE_COMMENT = 'questionnaire_comment',

  PERCENTAGE_ANSWERED = 'percentage_answered',

  //added in FE for panther files
  EMAIL_SENT = 'email_sent',
  ASSESSMENT_OUTCOME = 'assessment_outcome',
  DDQ_INHERITED_BY_TPTA = 'ddq_inherited_by_tpta',
  TPTA_INHERITED_DDQ = 'tpta_inherited_ddq',

  PRIMARY_CONTACT_CHANGED = 'primary_contact_changed',
  SECONDARY_CONTACT_CHANGED = 'secondary_contact_changed',
  THIRD_PARTY_CONTACT_CHANGED = 'third_party_contact_changed',
  TECHNICAL_CONTACT_CHANGED = 'technical_contact_changed',
  REQUESTER_CONTACT_CHANGED = 'requester_changed',

  USER_WATCHLIST = 'user_added_to_watchlist',
  //adding below for FE panther files and redirection requirements
  USER_WATCHLIST_EMPTY = 'user_watchlist_empty',
  USER_WATCHLIST_TASK = 'user_added_to_watchlist_task',
  USER_WATCHLIST_EMPTY_TASK = 'user_watchlist_empty_task',

  TIER_CHANGED = 'tier_changed',

  NONE = 'none',
}

// Not sent from backend but instead built on the FE
// Used for Todo List and Take Action List
export interface NotificationItem {
  tpsRecordId: string;
  containedRecordId?: string;
  tpsRecordSystemId: string;
  takeActionType: TakeActionType;
  expiresOnDate?: number;
  vendor?: string;
  tier?: string;
  personType?: PersonType;
  link: string;
  questionnaireType?: string;
}

// This text is hardcoded and required to display the correct message
export enum TakeActionType {
  HIGH_SEV_ISSUE_ACTIONABLE = 'high_sev_issue_actionable',
  DDQ_ACTIONABLE = 'ddq_actionable',
  TPTA_ACTIONABLE = 'tpta_actionable',
  SECURITY_QUESTIONNAIRE_INCOMPLETE = 'security_questionnaire_incomplete',
  TASK_ACTIONABLE = 'task_actionable',
  INTAKE_INCOMPLETE = 'intake_incomplete',
  CONSULT_ACTIONABLE = 'consult_actionable',
  SURVEY_ACTIONABLE = 'survey_actionable',
  MED_SEV_ISSUE_ACTIONABLE = 'med_sev_issue_actionable',
  LOW_SEV_ISSUE_ACTIONABLE = 'low_sev_issue_actionable',
  DDQ_NEW_COMMENTS = 'ddq_new_comments',
  TPTA_NEW_COMMENTS = 'tpta_new_comments',
  ISSUE_NEW_COMMENTS = 'issue_new_comments',
  HIGH_SEV_PAST_DUE = 'high_sev_past_due',
  HIGH_SEV_DUE_SOON = 'high_sev_due_soon',
  MED_SEV_PAST_DUE = 'med_sev_past_due',
  MED_SEV_DUE_SOON = 'med_sev_due_soon',
  LOW_SEV_PAST_DUE = 'low_sev_past_due',
  LOW_SEV_DUE_SOON = 'low_sev_due_soon',
  UNRANKED = 'unranked',
}

export enum SortResult {
  FIRST_RECORD_WINS = -1,
  SECOND_RECORD_WINS = 1,
  NO_RECORD_WINS = 0,
}

export enum RemediationOwner {
  THIRD_PARTY = 'third_party',
  AMAZON_BUSINESS = 'amazon_business',
}

export default Assessment;
