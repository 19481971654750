import { RoleType } from '../data/UserMetadata';
import {
  DEV_IRM_URL,
  UAT_IRM_URL,
  PROD_IRM_URL,
  QUERY_MAP,
  SnowUrlType,
  UrlParams,
  PortalType,
  TPS_CATALOG_QUERY_PARAMETER,
} from '../constants/urlConstants';
import { Activity, ActivityType } from '../data/RecordMetadata';
import { LOGOUT } from '../constants/linkConstants';
import { getActivityType } from './dataUtils';
import { ImpersonationIntentType } from '../context/UserProvider';

interface UrlComponents extends UrlParams {
  base: string;
}

export function getSnowDomainName(stage: string): string {
  switch (stage) {
    case 'beta':
      return DEV_IRM_URL;
    case 'prod':
      return PROD_IRM_URL;
    default:
      return UAT_IRM_URL;
  }
}

function getUrlComponents(linkType: SnowUrlType, portal: PortalType): UrlComponents {
  const urlParams = QUERY_MAP.get(portal)?.get(linkType);
  if (urlParams) {
    return {
      ...urlParams,
      path: `/${portal}`,
      base: getSnowDomainName('prod'),
    };
  }

  return { base: getSnowDomainName('prod') };
}

export default function getTPSPortalUrl({
  id,
  linkType,
  userRole,
  impersonationIntent,
  notesId,
}: {
  id?: string;
  linkType: SnowUrlType;
  userRole: RoleType;
  impersonationIntent: ImpersonationIntentType;
  notesId?: string;
}): string {
  let portal: PortalType = PortalType.SVDP;
  if (userRole === RoleType.REQUESTER || userRole === RoleType.ASSESSOR) {
    portal = PortalType.TPS;
  }
  const urlComponents = getUrlComponents(linkType, portal);
  let redirectUrl = urlComponents.base;

  if (linkType === SnowUrlType.INTAKE) return `${redirectUrl}/${portal}?${id}`;
  if (linkType === SnowUrlType.TPS_CATALOG) return `${redirectUrl}/${TPS_CATALOG_QUERY_PARAMETER}`;
  if (linkType === SnowUrlType.HOME) return `${redirectUrl}/${portal}`;

  if (urlComponents.path) redirectUrl += `${urlComponents.path}?`;

  if (urlComponents.queryParams) {
    for (const [key, value] of urlComponents.queryParams) {
      redirectUrl += `${key}=${value}&`;
    }
  }

  if (urlComponents.idKey) redirectUrl += `${urlComponents.idKey}=${id}&`;

  if (urlComponents.typeId && notesId) redirectUrl += `${urlComponents.typeId}=${notesId}&`;

  //adding this query param at the endfor 3P persona so on SNOW side they can recognize user redirected from MAP
  //skipping SNOW logout as it is specific to MAP and was added in Cognito allowed list & to avoid breaking changes
  if (userRole === RoleType.VENDOR && linkType !== LOGOUT.label) redirectUrl += 'source=map&';

  if (impersonationIntent === 'dualRole') redirectUrl += 'is1p=true';

  //removing '&' at the end as it may cause issue while SNOW logout url is part of Cognito allowed list
  if (redirectUrl[redirectUrl.length - 1] === '&') {
    redirectUrl = redirectUrl.substring(0, redirectUrl.length - 1);
  }

  return redirectUrl;
}

/**
 * This helper function will properly assign the link to each
 * update item based on the requirements here:
 * https://tiny.amazon.com/ctvkt6nr/quipCmTRCXAc
 *
 * @param activity
 * @param containingTpsRecordId
 * @param containingSysId
 * @param userRole
 * @returns
 */
export function getActivityLink(
  activity: Activity,
  userRole: RoleType,
  impersonationIntent: ImpersonationIntentType,
  typeId: string,
): string {
  //TODO have to modify below redirection for cases while working on redirection util to use recordType instead of recordId
  switch (getActivityType(activity)) {
    case ActivityType.STATE_CHANGE:
    case ActivityType.ASSESSMENT_STATE_CHANGE:
    case ActivityType.CONSULT_STATE_CHANGE:
    case ActivityType.TASK_STATE_CHANGE:
    case ActivityType.ISSUE_STATE_CHANGE:
    case ActivityType.INTAKE_COMPLETED:
    case ActivityType.COMMENT:
    case ActivityType.ADDITIONAL_COMMENTS:
    case ActivityType.ASSESSMENT_COMMENT:
    case ActivityType.ISSUE_COMMENT:
    case ActivityType.TASK_COMMENT:
    case ActivityType.ASSESSMENT_OUTCOME:
    case ActivityType.PRIMARY_CONTACT_CHANGED:
    case ActivityType.SECONDARY_CONTACT_CHANGED:
    case ActivityType.THIRD_PARTY_CONTACT_CHANGED:
    case ActivityType.TECHNICAL_CONTACT_CHANGED:
    case ActivityType.REQUESTER_CONTACT_CHANGED:
    case ActivityType.USER_WATCHLIST:
    case ActivityType.USER_WATCHLIST_EMPTY:
    case ActivityType.USER_WATCHLIST_TASK:
    case ActivityType.USER_WATCHLIST_EMPTY_TASK:
    case ActivityType.TIER_CHANGED:
      return getTPSPortalUrl({
        id: activity.tpsRecordSystemId!,
        linkType: getTpsRecordTypeLink(activity.tpsRecordType!),
        userRole,
        impersonationIntent,
      });

    case ActivityType.QUESTIONNAIRE_STATE_CHANGE:
    case ActivityType.QUESTIONNAIRE_COMMENT:
    case ActivityType.PERCENTAGE_ANSWERED:
      return getTPSPortalUrl({
        id: activity.tpsRecordSystemId!,
        linkType: getTpsRecordTypeLink(activity.tpsRecordType!),
        userRole,
        impersonationIntent,
        notesId: typeId,
      });

    case ActivityType.EMAIL_SENT:
    case ActivityType.TPTA_INHERITED_DDQ:
    case ActivityType.DDQ_INHERITED_BY_TPTA:
    case ActivityType.NONE:
    default:
      return '/';
  }
}

function getTpsRecordTypeLink(tpsRecordType: string): SnowUrlType {
  if (tpsRecordType in SnowUrlType) {
    return tpsRecordType as SnowUrlType;
  }
  return SnowUrlType.HOME;
}
