import React, { useEffect } from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import App from '../../components/App';
import LandingPage from '../../pages/landingPage/LandingPage';
import IssuesPage from '../../pages/issuesPage/IssuesPage';
import TasksPage from '../../pages/tasksPage/TasksPage';
import ConsultsPage from '../../pages/consultsPage/ConsultsPage';
import SurveysPage from '../../pages/surveysPage/SurveysPage';
import CompleteAssessments from '../../pages/completeAssessments/CompleteAssessments';
import ErrorPage from '../../pages/errorPages/ErrorPage';
import {
  LOGIN,
  DASHBOARD,
  ISSUES_PAGE,
  TASKS_PAGE,
  CONSULTS_PAGE,
  SURVEYS_PAGE,
  COMPLETE_ASSESSMENTS_PAGE,
  LOGOUT,
} from '../../constants/linkConstants';
import FederatedSignIn from '../Login/FederatedSignIn';
import LogoutPage from '../../pages/logoutPage/LogoutPage';
import { getSnowDomainName } from '../../utils/redirectionUtils';
import { DEEP_LINK, FALSE, LOGGING_OUT, TPS_USER_AUTHENTICATION_SIGNAL, TRUE } from '../../constants/constants';
import { Auth } from 'aws-amplify';
import { RoleType } from '../../data/UserMetadata';
import { useUserContext } from '../../context/UserProvider';

function getRoutes(role: RoleType): RouteObject[] {
  const routes = [
    {
      path: LOGIN.href,
      element: <FederatedSignIn />,
    },
    {
      path: DASHBOARD.href,
      element: <LandingPage />,
    },
    {
      path: ISSUES_PAGE.href,
      element: <IssuesPage />,
    },
    {
      path: SURVEYS_PAGE.href,
      element: <SurveysPage />,
    },
    {
      path: COMPLETE_ASSESSMENTS_PAGE.href,
      element: <CompleteAssessments />,
    },
    {
      path: LOGOUT.href,
      element: <LogoutPage />,
    },
  ];

  if (role === RoleType.REQUESTER)
    routes.push(
      {
        path: CONSULTS_PAGE.href,
        element: <ConsultsPage />,
      },
      {
        path: TASKS_PAGE.href,
        element: <TasksPage />,
      },
    );

  //uncomment this for local development
  // routes.push(
  //   { path: CONSULTS_PAGE.href, element: <ConsultsPage /> },
  //   { path: TASKS_PAGE.href, element: <TasksPage /> },
  // );
  return routes;
}

export default function PortalRouter() {
  const temp = window.localStorage.getItem(DEEP_LINK);
  if (temp) {
    window.localStorage.removeItem(DEEP_LINK);
    window.location.href = temp;
  }

  const { role } = useUserContext();
  useEffect(() => {
    window.addEventListener('storage', function (event) {
      if (event.key === TPS_USER_AUTHENTICATION_SIGNAL && event.newValue === FALSE) {
        // skipping logout route page load
        if (window.location.pathname !== `/${LOGOUT.href}` && role === RoleType.VENDOR) {
          Auth.currentAuthenticatedUser().catch((_err) => {
            window.location.reload();
          });
        }
      } else if (event.key === LOGGING_OUT && event.newValue === TRUE) {
        if (role !== RoleType.VENDOR) {
          // Midway 1P user's doesn't have logout so instead of reloading page redirecting them to tps home page
          window.location.href = `${getSnowDomainName('prod')}/tps`;
        }
      }
    });
  }, []);

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: DASHBOARD.href,
          element: <App />,
          errorElement: <ErrorPage />,
          children: getRoutes(role),
        },
      ])}
    />
  );
}
