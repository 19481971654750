import { API } from 'aws-amplify';
import { API_FAILURE, GET_OPERATION_ERROR, POST_OPERATION_ERROR, PUT_OPERATION_ERROR } from '../constants/constants';
import MetricsPublisher from '../logging/MetricsPublisher';
import KatalLogger from '@amzn/katal-logger';

type RequestParametersType = {
  headers: {
    Authorization: string;
    'X-Forwarded-User'?: string;
  };
  queryStringParameters?: { [key: string]: string };
  body?: any;
};

const getAuth = (authToken: string): RequestParametersType => ({
  headers: {
    Authorization: authToken,
  },
});

interface BaseParams {
  apiPath: string;
  name: string;
  authToken: string;
  metricsPublisher: MetricsPublisher;
  logger: KatalLogger;
}

interface GetParams<T> extends BaseParams {
  user?: string;
  queryParams?: Record<string, string>;
  emptyResponseConfig?: Record<number, T>;
}

interface PutParams extends BaseParams {
  body?: any;
}

type PostParams = PutParams;

export const get = async <T>({
  apiPath,
  name,
  authToken,
  metricsPublisher,
  logger,
  user,
  queryParams,
  emptyResponseConfig,
}: GetParams<T>): Promise<T> => {
  const stopwatch = metricsPublisher.createTimerStopwatch('Time').withMonitor();
  try {
    const parameters = getAuth(authToken);
    if (user) parameters.headers['X-Forwarded-User'] = user;
    if (queryParams) parameters.queryStringParameters = queryParams;

    stopwatch.start();
    const data = await API.get(`prod-ApiGateway`, apiPath, parameters);
    stopwatch.stop();
    metricsPublisher.publishSuccessMetrics(`get${name}`, stopwatch.value);
    return data;
  } catch (error: any) {
    stopwatch.stop();
    if (error?.response?.status && emptyResponseConfig && emptyResponseConfig[error.response.status]) {
      const message = `User has no ${name} record(s)`;
      console.log(message);
      logger.warn(message);
      return emptyResponseConfig[error.response.status];
    } else {
      console.log(`${GET_OPERATION_ERROR} for ${apiPath}: ${error}`);
      logger.error(`${API_FAILURE}: GET ${apiPath}: ${error}`);
    }
    metricsPublisher.publishFailureMetrics(error, `get${name}`, stopwatch.value);
    throw error;
  }
};

export const put = async ({ apiPath, name, authToken, metricsPublisher, logger, body }: PutParams) => {
  const stopwatch = metricsPublisher.createTimerStopwatch('Time').withMonitor();
  try {
    const parameters = getAuth(authToken);
    if (body) {
      parameters.body = body;
    }

    stopwatch.start();
    const data = await API.put(`prod-ApiGateway`, apiPath, parameters);
    stopwatch.stop();
    metricsPublisher.publishSuccessMetrics(`delete${name}`, stopwatch.value);

    return data;
  } catch (error) {
    stopwatch.stop();
    console.log(`${PUT_OPERATION_ERROR} for ${apiPath}: ${error}`);
    logger.error(`${API_FAILURE}: PUT ${apiPath}: ${error}`);
    metricsPublisher.publishFailureMetrics(error, `delete${name}`, stopwatch.value);
    throw error;
  }
};

export const post = async ({ apiPath, name, authToken, metricsPublisher, logger, body }: PostParams) => {
  const stopwatch = metricsPublisher.createTimerStopwatch('Time').withMonitor();
  try {
    const parameters = getAuth(authToken);
    if (body) {
      parameters.body = body;
    }

    stopwatch.start();
    const data = await API.post(`prod-ApiGateway`, apiPath, parameters);
    stopwatch.stop();
    metricsPublisher.publishSuccessMetrics(`create${name}`, stopwatch.value);

    return data;
  } catch (error) {
    stopwatch.stop();
    console.log(`${POST_OPERATION_ERROR} for ${apiPath}: ${error}`);
    logger.error(`${API_FAILURE}: POST ${apiPath}: ${error}`);
    metricsPublisher.publishFailureMetrics(error, `create${name}`, stopwatch.value);
    throw error;
  }
};
