/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';

export const getLogger = (authToken: string) => {
  const logger = new KatalLogger({
    url: 'https://y653szfiy1.execute-api.us-west-2.amazonaws.com/prod/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: false,
    headers: {
      Authorization: authToken,
    },
  });
  logger.addErrorListener();
  return logger;
};
